import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const Typing = () => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            const response = await fetch(`https://pivpn.xyz/api/cyoa?prompt=${inputValue}&max_time=3&num_beams=0&penalty_alpha=1&early_stopping=true&output_length=25&top_k=100&top_p=0.9&do_sample=true&typical_p=0.6&temperature=1.2`);
            const data = await response.json();
            setSuggestions(data);
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    const handleKeyDown = (e) => {
        if (e.key === 'Tab' && suggestions.length > 0) {
            e.preventDefault();
            setInputValue(suggestions[0]);
            setSuggestions([]);
        }
    }

    return (
        <><div>
            {/* Your existing code goes here */}
            <textarea
                style={{ width: "100%", height: "400px" }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
            />
        </div><div><ul>
            {suggestions.map((suggestion) => (
                <li>{suggestion}</li>
            ))}
        </ul></div></>
    );
};


export default Typing;
