import React from "react";
import { useState } from "react";
import axios from "axios";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Slider } from "primereact/slider";
// import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
// import { AutoComplete } from "primereact/autocomplete";
// import { Card } from "primereact/card";
// import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
// import { TabView } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";

const App = () => {
  return (
    <div>
      {/* Your existing code goes here */}
      <TextArea />
    </div>
  );
};

const TextArea = () => {
  const [text, setText] = useState("In the beginning there was");
  const [results, setResults] = useState("Results will appear here");
  const [output_length, setOutputLength] = useState(10);
  const [num_beams, setNumBeams] = useState(0);
  const [early_stopping, setEarlyStopping] = useState(true);
  const [top_k, setTopK] = useState(300);
  const [top_p, setTopP] = useState(0.99);
  const [do_sample, setDoSample] = useState(true);
  const [typical_p, setTypicalP] = useState(0.88);
  const [penalty_alpha, setPenaltyAlpha] = useState(1.25);
  const [temperature, setTemperature] = useState(0.75);
  const [maxTime, setMaxTime] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  function callAPI() {
    setResults("Fetching results...");
    setIsLoading(true);
    // Call FastAPI with Axios and update results variable
    axios
      .get(
        `https://pivpn.xyz/api/cyoa?prompt=${text}&max_time=${maxTime}&num_beams=${num_beams}&penalty_alpha=${penalty_alpha}&early_stopping=${early_stopping}&output_length=${output_length}&top_k=${top_k}&top_p=${top_p}&do_sample=${do_sample}&typical_p=${typical_p}&temperature=${temperature}`,
        {
          text: text,
          maxTime: maxTime,
          num_beams: num_beams,
          penalty_alpha: penalty_alpha,
          early_stopping: early_stopping,
          output_length: output_length,
          top_k: top_k,
          top_p: top_p,
          do_sample: do_sample,
          typical_p: typical_p,
          temperature: temperature,
          num_return_sequences: 1,
        }
      )
      .then((response) => {
        // setText(response.data);
        setResults(response.data);
        setIsLoading(false);
      });
  }

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" && event.ctrlKey) {
  //     setText(text + results);
  //   } else if (
  //     (event.key === "k" && event.ctrlKey) ||
  //     (event.key === "Enter" && event.metaKey)
  //   ) {
  //     callAPI();
  //   }
  // };

  return (
    <div className="grid">
      <Panel className="p-col-12">
        <div className="p-grid p-fluid">
          <h3>Text Generation</h3>
          <InputTextarea
            id="textarea"
            rows={10}
            cols={60}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <br />
          <div className="card-result">{results}</div>
          <br />

          <Button
            label="Get Results"
            onClick={() => callAPI()}
            // style={{ width: "25%" }}
            class="align-items-center align-content-center justify-content border-round"
            disabled={isLoading}
          />

          <Button
            label="Add to text"
            icon="pi pi-check"
            onClick={() => {
              setText(text + results);
              setResults(null);
            }}
            // style={{ width: "25%" }}
            disabled={
              results === null ||
              results === "Fetching Results..." ||
              results === "" ||
              isLoading
            }
          />
        </div>
        <Accordion activeIndex={[0]} multiple='true'>
          <AccordionTab header="Settings for Model Output">
            <div className="p-grid p-fluid">
              <span>Max Time (seconds):</span>
              <span>{maxTime}</span>
              <Slider
                id="max-time"
                value={maxTime}
                onChange={(event) => setMaxTime(event.value)}
                min={1}
                max={120}
                style={{ padding: "5px", margin: "5px" }}
              />
              <br />
              <label htmlFor="output-length">Output Length (tokens):</label>
              <span>{output_length}</span>
              <Slider
                id="output-length"
                value={output_length}
                onChange={(event) => setOutputLength(event.value)}
                min={1}
                max={10000}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="temperature">Temperature (How creative?):</label>
              <span>{temperature}</span>
              <Slider
                id="temperature"
                value={temperature}
                onChange={(event) => setTemperature(event.value)}
                min={0}
                max={1}
                step={0.01}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="num-beams">Num Beams (default = 0):</label>
              <span>{num_beams}</span>
              <Slider
                id="num-beams"
                value={num_beams}
                onChange={(event) => setNumBeams(event.value)}
                min={0}
                max={100}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="top-k">Top K (amount of words to use): </label>
              <span>{top_k}</span>
              <Slider
                id="top-k"
                value={top_k}
                onChange={(event) => setTopK(event.value)}
                min={0}
                max={10000}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="top-p">Top P (most likely word at best):</label>
              <span>{top_p}</span>
              <Slider
                id="top-p"
                value={top_p}
                onChange={(event) => setTopP(event.value)}
                min={0}
                max={1}
                step={0.01}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="typical-p">Typical P (usually use most likely word?):</label>
              <span>{typical_p}</span>
              <Slider
                id="typical-p"
                value={typical_p}
                onChange={(event) => setTypicalP(event.value)}
                min={0}
                max={1}
                step={0.01}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="penalty-alpha">Penalty Alpha (Penalty for Repeating):</label>
              <span>{penalty_alpha}</span>
              <Slider
                id="penalty-alpha"
                value={penalty_alpha}
                onChange={(event) => setPenaltyAlpha(event.value)}
                min={0}
                max={5}
                step={0.1}
                style={{ padding: "5px", margin: "5px" }}
              />
              <label htmlFor="early-stopping">Early Stopping: </label>
              <Checkbox
                id="early-stopping"
                checked={early_stopping}
                onChange={(event) => setEarlyStopping(event.checked)}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              <br />
              <label htmlFor="do-sample">Do Sample: </label>
              <Checkbox
                id="do-sample"
                checked={do_sample}
                onChange={(event) => setDoSample(event.checked)}
                style={{ padding: "5px", margin: "5px" }}
              />{" "}
              {/* <span>{do_sample}</span> */}
              <br />
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
      {/* <Panel
        toggleable
        collapsed
        icon="pi pi-arrow-down"
        header="Setup your Model Generation Parameters"
      > */}

      {/* </Panel> */}
    </div>
  );
};

export default App;
