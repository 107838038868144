import React, { useEffect, useState } from 'react';
import './AnimatedCharacterModal.css';

interface AnimatedCharacterModalProps {
  isLoading: boolean;
}

const AnimatedCharacterModal: React.FC<AnimatedCharacterModalProps> = ({ isLoading }) => {
  const [countdown, setCountdown] = useState(10);
  const [explode, setExplode] = useState(false);
  const [quiver, setQuiver] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setCountdown(10);
      setExplode(false);
      return;
    }

    const timer = setInterval(() => {
      setQuiver(false);
      setCountdown((prevCountdown) => prevCountdown - 1);
      setQuiver(true);
    }, 1000);

    const explosionTimeout = setTimeout(() => {
      setExplode(true);
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(explosionTimeout);
    };
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className={`countdown ${explode ? 'explode' : ''} ${quiver ? 'quiver' : ''}`}>{countdown}</div>
        <p>Computing the Top 3 Possibilities For You...</p>
      </div>
    </div>
  );
};

export default AnimatedCharacterModal;
