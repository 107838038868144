import React, { useState, useRef } from "react";
import axios from "axios";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import FateApp from "./FateApp";
// import { useRef } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Chip } from "primereact/chip";
import { Timeline } from "primereact/timeline";
import anime from "animejs/lib/anime.es.js";
import AnimatedCharacterModal from './AnimatedCharacterModal.tsx';
import { ToggleButton } from 'primereact/togglebutton';

import { SelectButton } from 'primereact/selectbutton';


const FateStory = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [story, setStory] = useState("");
  const [lastResult, setLastResult] = useState(null);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  // const [option, setOptions] = useState([]);
  const [results, setResults] = useState([]);
  const [next, setNext] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  // const [storyId, setStoryId] = useState(null);
  // const [prompt, setPrompt] = useState(null);
  // const toast = useRef(null);
  // const [binary, setBinary] = useState(null);
  // const [more, setMore] = useState(false);
  // const moreOptions = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tags, setTags] = useState(null);
  const [score, setScore] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  // const avgScore = score.reduce((a, b) => a + b, 0) / score.length;
  // const [toast, setToast] = useState(null);
  // const [isNexted, setIsNexted] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [stepNum, setStepNum] = useState(0);
  const [userText, setUserText] = useState(null);
  const [selectedResultIndex, setSelectedResultIndex] = useState(null);
  const [toggleSelected, setToggleSelected] = useState(false);


  const filteredScore = score.filter(
    (item) => typeof item === "number" && item !== 0
  );
  const avgScore =
    filteredScore.reduce((a, b) => a + b, 0) / filteredScore.length;

  // const customizedMarker = (item) => {
  //   if (item.value > avgScore) {
  //     return (
  //       <span
  //         className="custom-marker shadow-1"
  //         style={{ backgroundColor: item.color }}
  //       >
  //         <i className="pi pi-arrow-up pi-2x"></i>
  //       </span>
  //     );
  //   }
  //   if (item.value <= avgScore) {
  //     return (
  //       <span
  //         className="custom-marker shadow-1"
  //         style={{ backgroundColor: item.color }}
  //       >
  //         <i className="pi pi-arrow-down pi-2x"></i>
  //       </span>
  //     );
  //   }
  // };


  const handleGenerate = async () => {
    setIsGenerating(true);
    try {
      const response = await axios.get(
        `https://pivpn.xyz/api/fate/continue?storyId=${props.storyId}`
      );
      setStep(response.data.step_num);
      if (results) {
        // use spread operator to add new options to the existing results array
        setResults([
          ...results,
          ...response.data.results.map((result) => ({
            text: result.text,
            id: result.optionID,
          })),
        ])
        setSelected(false);
      } else {
        setResults(
          response.data.results.map((result) => ({
            text: result.text,
            id: result.UUID,
          }))
        );
      }
      setIsGenerating(false);
    } catch (err) {
      console.error(err);
    }
  };


  const handleOptionSelect = async (id, step, text) => {
    try {
      // Increment step_num by 1 before sending the request
      const response = await axios.get(
        `https://pivpn.xyz/api/add_step?storyId=${props.storyId}&step=${step +
        1}&option_id=${id}`
      );
      if (response.data.tags) {
        setTags(response.data.tags);
      }
      if (response.data.score) {
        setScore((prevScore) => [...prevScore, response.data.score].slice(-10));
      }
      // set
      // Update the story state with the latest information
      //setStory(currentStory => currentStory + option);
      // toast.current.show({
      //   severity: "success",
      //   summary: "Opton Added",
      //   detail: "New fragment added to the story, keep going!",
      // });
      if (!next) {
        //setLastResult(option);
        setNext(text);
        // animateNext();
      } else {
        if (!lastResult) {
          setLastResult(text);
        } else {
          setNext(next + lastResult);
          setLastResult(text);
        }
      }
      console.log("Clearing results");
      setResults([]);
      // setIsNexted(true);
      setSelected(true);
      // handleGenerate();
      // console.log(response);
    } catch (err) {
      // console.error(err);
    }
  };
  const handleRemoveOption = async (index) => {
    // Remove the result from the array
    setIsRemoving(true);
    const newResults = results.filter((_, idx) => idx !== index);
    setResults(newResults);

    // Insert the placeholder object into the results array
    const placeholder = {
      id: 'placeholder',
      text: 'Loading new option...',
    };
    setResults([...newResults, placeholder]);

    // Call the API to get a new result
    try {
      const url = `https://pivpn.xyz/api/fate/continue_once?storyId=${props.storyId}`; // Replace with your actual API URL
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();

        // Check if the response contains the expected data
        if (data && data.results && Array.isArray(data.results) && data.results.length > 0 && data.results[0].text && data.results[0].optionID) {
          const newResult = {
            text: data.results[0].text,
            id: data.results[0].optionID,
          };

          // Replace the placeholder object with the new result
          setResults((prevResults) =>
            prevResults.map((result) =>
              result.id === 'placeholder' ? newResult : result
            )
          );
          setIsRemoving(false);
        } else {
          console.error('Error fetching new result: Unexpected data format');
        }
      } else {
        console.error('Error fetching new result:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching new result:', error);
    }
  };



  const handleUnload = () => {
    setStory(null);
    // setStoryId(null);
    // setIsLoaded(false);
  };

  const animateLastResult = () => {
    // Wrap every letter in a span
    let textWrapper = document.querySelector(".styled-last-result");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /([^\x00-\x80]|\w)/g,
      "<span class='letter'>$&</span>"
    );

    anime.timeline({ loop: false }).add({
      targets: ".styled-last-result .letter",
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 600 / textWrapper.textContent.length,
      offset: "-=775",
      delay: (el, i) => 34 * (i + 1),
    });
    // .add({
    //   targets: ".styled-last-result",
    //   opacity: 0,
    //   duration: 1000,
    //   easing: "easeOutExpo",
    //   delay: 1000,
    // });
  };

  const animateNext = () => {
    let textWrapper = document.querySelector(".styled-next");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /([^\x00-\x80]|\w)/g,
      "<span class='letter'>$&</span>"
    );

    anime.timeline({ loop: false }).add({
      targets: ".styled-next .letter",
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 600 / textWrapper.textContent.length,
      offset: "-=775",
      delay: (el, i) => 34 * (i + 1),
    });
  };

  const animateStory = () => {
    let textWrapper = document.querySelector(".story-text");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /([^\x00-\x80]|\w)/g,
      "<span class='letter'>$&</span>"
    );

    anime.timeline({ loop: false }).add({
      targets: ".story-text .letter",
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 50,
      offset: "-=775",
      delay: (el, i) => 3 * (i + 1),
    });
  };

  React.useEffect(() => {
    if (lastResult) {
      animateLastResult();
    }
  }, [lastResult]);

  React.useEffect(() => {
    if (next && !lastResult) {
      animateNext();
    }
  }, [next]);

  React.useEffect(() => {
    if (story) {
      animateStory();
    }
  }, [story]);

  // Add this function to update userText when the user types in the textarea
  const handleUserInputChange = (event) => {
    setUserText(event.target.value);
  };

  const [autoSelectEnabled, setAutoSelectEnabled] = useState(false);

  React.useEffect(() => {
    let intervalId;

    const handleAutoSelect = () => {
      const eligibleResults = results.filter(result => !result.text.includes("</s>"));
      if (eligibleResults.length > 0) {
        const randomIndex = Math.floor(Math.random() * eligibleResults.length);
        const randomResult = eligibleResults[randomIndex];
        handleOptionSelect(randomResult.id, step, randomResult.text);
      }
    };


    if (autoSelectEnabled) {
      intervalId = setInterval(handleAutoSelect, 1000); // Adjust the interval duration as needed
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [results, autoSelectEnabled, handleOptionSelect, step]);

  const handleToggleChange = (e) => {
    const selected = e.value;
    setAutoSelectEnabled(selected);
  };

  let options = ["Auto Select", "Manual Select"];


  React.useEffect(() => {
    const fetchStory = async () => {
      setIsLoading(true);
      // toast.current.show({
      //   severity: "success",
      //   summary: "Fetching Story and Story Options!",
      //   detail: "New Fragments Incoming!",
      // });
      if (!isLoaded) {
        try {
          const response = await axios.get(
            `https://pivpn.xyz/api/fate?storyId=${props.storyId}`
          );
          setStory(response.data.prompt);
          setStep(response.data.step);
          setStepNum(response.data.step);
          // setStoryId(response.data.storyId);
          setTags(response.data.tags);
          // setPrompt(story);
          handleGenerate();
        } catch (err) {
          setError(err);
        }
        setIsLoading(false);
        setIsLoaded(true);
      } else {
        handleGenerate();
      }
    };
    if (!isLoaded) {
      fetchStory();
    }
    if (selected) {
      handleGenerate();
    }
  }, [props.storyId, selected]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!story) {
    return <FateApp />;
  }

  return (
    <>
      <div>
        {/* {toast.current && (
          <Toast
            ref={toast}
            position="top-right"
            style={{ minWidth: "20rem" }}
          />
        )} */}

        <Panel header={`Search key: ${props.storyId.slice(0, 8)}`}>
          <h2>Number of Story Steps: {step + 1}</h2>
          <h3>Your On-Going Score</h3>
          <em>
            This shows as you progress, shows how varied your text selections
            are!
            {isFinite(avgScore) ? (
              <span>
                &nbsp;
                <b>Your current average score is: {avgScore.toFixed(0)} !</b>
              </span>
            ) : (
              <span></span>
            )}
          </em>
          {score && score.length > 0 && (
            <div>
              <Timeline
                value={score}
                layout="horizontal"
                align="alternate"
                content={(item) => item}
                opposite={<span>&nbsp;</span>}
                style={{ "margin-bottom": "1rem", alignSelf: "center" }}
                itemStyle={(item) => {
                  if (item > avgScore) {
                    return { background: "green" };
                  } else if (item < avgScore) {
                    return { background: "red" };
                  }
                }}
                // marker={customizedMarker}
                marker={(item) => {
                  if (item === 0) {
                    return (
                      <span>
                        <i className="pi pi-link" />
                      </span>
                    );
                  }
                  if (item > avgScore) {
                    return (
                      <span>
                        {/* <i className="pi pi-angle-double-up" /> */}
                        <Button
                          icon="pi pi-angle-double-up"
                          className="p-button-rounded p-button-secondary p-button-outlined"
                          style={{
                            "background-color": "var(--green-400)",
                            color: "white",
                          }}
                        />
                      </span>
                    );
                  }
                  if (item <= avgScore) {
                    return (
                      <span>
                        {/* <i className="pi pi-angle-double-down" /> */}
                        <Button
                          icon="pi pi-angle-double-down"
                          className="p-button-rounded p-button-secondary p-button-outlined"
                          style={{
                            "background-color": "var(--red-400)",
                            color: "white",
                          }}
                        />
                      </span>
                    );
                  }
                }}
              />
            </div>
          )}
          <Card
            style={{
              background: "#FFFFCC",
            }}
          // header="Our story so far..."
          >
            <h2>Our story so far...</h2>
            {tags && tags.length > 0 ? (
              <div>
                <span style={{ "font-weight": "bold" }}>Tags: </span>
                {tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    className="p-mr-2"
                    style={{ margin: "5px" }}
                  />
                ))}
              </div>
            ) : null}
            <span className="story-text" style={{ whiteSpace: "pre-wrap" }}>
              {story}
            </span>
            {next ? (
              <span className="styled-next" style={{ whiteSpace: "pre-wrap" }}>
                {next}
              </span>
            ) : null}
            {lastResult ? (
              <span
                className="styled-last-result"
                style={{ whiteSpace: "pre-wrap" }}
              // onLoad={() => animateLastResult(lastResult)}
              >
                {lastResult}
              </span>
            ) : null}
          </Card>

          <div className="ai-results">
            <h3>AI Suggestions</h3>
            {
              results.map((result, index) => (
                <div key={result.id}>
                  <Card
                    // className={`result-${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      margin: "5px",
                      border: "1px dashed #000",
                    }}
                    className={`result-cards ${result.id === 'placeholder' ? 'placeholder-card' : ''}`}
                  >
                    <div style={{
                      // display: "flex",
                      justifyContent: "align-left",
                      alignItems: "center",
                    }}>
                      <Button
                        onClick={() => handleOptionSelect(result.id, step, result.text)}
                        icon={
                          result.text.includes("</s>") ? "pi pi-ban" : "pi pi-check"
                        }
                        className="p-button-rounded"
                        aria-label="Filter"
                        disabled={result.text.includes("</s>") || result.id === 'placeholder' || isRemoving}
                      />
                      <Button
                        onClick={() => handleRemoveOption(index, result.id, step, result.text)}
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger"
                        aria-label="Remove"
                        disabled={result.id === 'placeholder' || isRemoving}
                      />
                      <span
                        style={{ marginLeft: "25px" }}
                        className={
                          result.text.includes("</s>")
                            ? "result-text-disabled"
                            : "result-text-enabled"
                        }
                      >
                        {result.text.replace(
                          "</s>",
                          " 🔚 Disabled, ends story!"
                        )}
                      </span>

                    </div>
                    {/* <span style={{ whiteSpace: "pre-wrap" }}>{result.id}</span> */}
                  </Card>
                </div>
              ))
            }
          </div>


        </Panel>
      </div>
      <span>
        {isGenerating ? (
          // <Button
          //   onClick={() => handleGenerate()}
          //   style={{ margin: "5px" }}
          //   icon="pi pi-spin pi-spinner"
          //   label="Generating..."
          //   disabled
          // // className=".throbbing-button"
          // >
          //   {/* &nbsp;Generating... */}
          // </Button>
          <AnimatedCharacterModal isLoading={isGenerating} />
        ) : results && results.length > 0 ? (
          <Button
            onClick={() => handleGenerate()}
            style={{ margin: "5px" }}
            icon="pi pi-palette"
            label="Generate More Options (<10 seconds)"
          >
            {/* &nbsp;Generate More Options (10 seconds) */}
          </Button>
        ) : (
          <Button
            onClick={() => handleGenerate()}
            style={{ margin: "5px" }}
            icon="pi pi-palette"
            label="Generate Options (<10 seconds)"
          >
            {/* Generate Options (10 seconds) */}
          </Button>
        )}
      </span>
      <span>
        <Button
          onClick={() => handleUnload()}
          style={{ margin: "5px" }}
          icon="pi pi-chevron-circle-left"
          label="Go Back to Story List"
        >
          {/* &nbsp;Go Back to Story List */}
        </Button>
        {/* <ToggleButton checked={toggleSelected} onChange={handleToggleChange} onIcon="pi pi-check" offIcon="pi pi-times" label="Auto-Select Options?" /> */}
        {/* <SelectButton value={toggleSelected} options={toggleOptions} onChange={handleToggleChange} >Auto Generate & Select?</SelectButton> */}
        {/* <SelectButton value={toggleSelected} onChange={handleToggleChange} options={options} /> */}
        <SelectButton
          value={autoSelectEnabled}
          options={[
            { label: 'Manually Select', value: false },
            { label: 'Auto Select', value: true }
          ]}
          onChange={handleToggleChange}
        />

      </span>
      <h4 style={{ textAlign: "center" }}>
        Story Search Key/ID: {props.storyId}
      </h4>
      <Accordion>
        <AccordionTab
          // expandIcon="pi pi-question-circle"
          header={
            <React.Fragment>
              <i className="pi pi-question-circle"></i>
              <span>&nbsp;</span>
              <i className="pi pi-sitemap"></i>
              <span>&nbsp;</span>
              <span>Help & How To Play</span>
              <span>&nbsp;</span>
              <i className="pi pi-palette"></i>
              <span>&nbsp;</span>
              <i className="pi pi-user-edit"></i>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <i className="pi pi-chevron-left"></i>
            </React.Fragment>
          }
        >
          <h3>Writing a story</h3>
          The goal of story writing with BLOOM is to generate a story that is
          cohesive, one that makes sense, and one that is interesting. To do
          this, you must follow a few simple rules:
          <ul>
            <li>
              Pick the option that makes the most sense in the context of the
              story.
            </li>
            <li>
              Try to avoid picking options that are too similar to each other.
            </li>
            <li>Introduce as may twists and turns as you can!</li>
            <li>
              Try to pick a fragment that's the <em>best prompt</em> for the{" "}
              <em>next</em> part of the story
            </li>
            <li>
              Avoid repeating the same words if you can avoid it, don't let
              the model get "trapped" in a loop
            </li>
            <li>
              Try to keep the story moving forward, don't get stuck on one
              idea
            </li>
            <li>Have fun!</li>
          </ul>
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default FateStory;
