import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import Router from "./router";
import Home from "./Home";
import Typing from "./Typing";
import App from "./App"; // import the component for your app playground
// import App2 from "./CYOF"; // import the component for your CYOF app
import FateApp from "./FateApp"; // import the component for your Fate app
// import reportWebVitals from './reportWebVitals';
// import './ts-axios/api';
import { useState, useEffect } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { useEffect } from "react";
// import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css';
// import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
//primereact/resources/themes/bootstrap4-light-blue/theme.css
//import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Menubar } from "primereact/menubar";
import { StyleClass } from "primereact/styleclass";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
// import 'bootstrap/dist/css/bootstrap.css';
// import { Panel } from "primereact/panel";
// import '/node_modules/primeflex/primeflex.css'
// import { Link, Route, BrowserRouter, Router } from "react-router-dom";
// import { HashRouter, Route, Routes } from "react-router-dom";
// import { Link } from "react-router-dom";
// import "animate.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Index() {
  const [selectedComponent, setSelectedComponent] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("storyId");

  function handleRefreshClick() {
    window.location.reload();
  }

  useEffect(() => {
    if (id) {
      setSelectedComponent("FateApp");
    } else {
      setSelectedComponent("NoMatch");
    }
  }, [id]);

  const items = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      to: "/",
      command: () => setSelectedComponent("Home"),
    },
    {
      label: "Choose Your Own Fate!",
      icon: "pi pi-fw pi-book",
      to: "/fate",
      command: () => setSelectedComponent("FateApp"),
    },
    {
      label: "Playground",
      icon: "pi pi-fw pi-align-center",
      to: "/playground",
      command: () => setSelectedComponent("App"),
    },
    // {
    //   label: "Typing with BLOOM",
    //   icon: "pi pi-fw pi-align-center",
    //   to: "/typing",
    //   command: () => setSelectedComponent("Typing"),
    // },
    // {
    //   label: "Create Disposable Story",
    //   icon: "pi pi-fw pi-pencil",
    //   // to: "/disposable-fate",
    //   command: () => setSelectedComponent("App2"),
    // },

    // {
    //   label: "About/History (coming soon)",
    //   icon: "pi pi-fw pi-info-circle",
    //   // to: "/about",
    //   //command: () => setSelectedComponent("About"),
    // },
    {
      label: "Reload Application",
      icon: "pi pi-refresh",
      command: () => handleRefreshClick(),
    },
  ];

  function renderSelectedComponent() {
    if (selectedComponent === "App") {
      return <App />;
    }
    else if (selectedComponent === "Typing") {
      return <Typing />;
    }
    else if (selectedComponent === "FateApp") {
      return <FateApp />;
    }
    else if (selectedComponent === null) {
      return (
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/fate" component={FateApp} />
          <Route path="/playground" component={App} />
        </Router>
      );
    }
    else {
      return <Home />;
    }
  }

  return (
    <div className="entire-application">
      <Menubar model={items} />
      <div className="selected-component">{renderSelectedComponent()}</div>
      <Router>
        <Routes>
          <Route path="/" component={Home} />
          <Route path="/fate" component={FateApp} />
          <Route path="/playground" component={App} />
          <Route path="/typing" component={Typing} />
        </Routes>
      </Router>
      {/* <div className="selected-component">{renderSelectedComponent()}</div> */}
    </div>
  );
}
root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/C

export default Index;
