import React, { useState, useEffect } from "react";
import axios from "axios";
import FateStory from "./FateStory";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Badge } from "primereact/badge";
// import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { Panel } from "primereact/panel";
// import { Card } from "primereact/card";
import moment from "moment";
const FateApp = () => {
  const [storyId, setStoryId] = useState();
  // const [prompt, setPrompt] = useState(null);
  // const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(null);
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("storyId");
    setStoryId(id);
    /* Setting the initial state of the filters. */
    initFilters1();
    if (!id) {
      handleLoadStories(page, queryParams.get("page"));
    }
    if (id) {
      handleLoadStory(id);
    }
  }, []);

  const handleLoadStories = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://pivpn.xyz/api/lastUpdatedRecords"
      );
      setRecords(response.data.lastUpdatedRecords);
    } catch (err) {
      // setError(err);
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleLoadStory = (_id) => {
    setStoryId(_id);
  };

  const handleStartNewStory = async (_id) => {
    // event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.get("https://pivpn.xyz/api/fate");
      setStoryId(response.data.storyId);
      // setPrompt(response.data.prompt);
      setStep(response.data.last_step_tensors);
    } catch (err) {
      // setError(err);
      console.log(err);
    }
    setIsLoading(false);
  };

  const header1 = () => {
    return (
      <div className="flex justify-content-between">
        {/* <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        /> */}
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Story ID / Keyword Search"
          />
        </span>
      </div>
    );
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      storyId: { value: null, matchMode: FilterMatchMode.CONTAINS },
      first_text: { value: null, matchMode: FilterMatchMode.CONTAINS },
      last_text: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  if (!storyId) {
    return (
      <div className="fate-app">
        <br />
        {/* <h1>Choose Your Own Fate!</h1> */}
        <Panel
          header="Choose Your Own Fate!"
          style={{
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <div className="surface-0 text-700 text-center">
            <div className="text-blue-600 font-bold mb-3">
              <i className="pi pi-discord"></i>&nbsp;POWERED BY DISCORD
            </div>
            <div className="text-900 font-bold text-5xl mb-3">
              Join Our Design Community
            </div>
            <div className="text-700 text-2xl mb-5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit
              numquam eligendi quos.
            </div>
            <Button
              label="Join Now"
              icon="pi pi-discord"
              className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
            />
          </div> */}
          {/* <Card> */}
          {
            isLoading ? (
              <Button
                label="Starting Your Story..."
                icon="pi pi-spinner pi-spin"
                className="animate__animated animate__headShake"
                disabled
              />
            ) : (
              <Button
                label="Start a New Story"
                onClick={handleStartNewStory}
                icon="pi pi-book"
                className="animate__animated animate__headShake"
              />
            )
          }

          <br />
          <p>
            Welcome to the world of limitless storytelling possibilities! With OPT, powered by Meta's OPT Large Language model from Hugging Face, you have the chance to <b>generate a story with AI</b> like never before.
            <p />
            <h3>Where Will Your Imagination Take You?</h3>
            <p />
            Begin a new story with a random starting prompt or take a daring leap by using a portion from <b>today's news</b> as your entry point into something <em>new and <b>different</b></em>. Scroll down to explore the creative narratives others have crafted.
            <p />
            What can you build? Where will you journey from your starting point? What actions will your characters take? What dialogues will unfold? Challenge yourself to keep the story alive without reaching its end too quickly. We won't let you select a story fragment that concludes the tale prematurely. Witness the magic of open-ended text generation!
            <p />
            <h3>Unleash Your Imagination!</h3>
            <p />
            Your creative canvas is vast, and your choices determine the path of your story. Craft a narrative that's coherent, relevant, and imaginative. Can you make the AI think like you? Or perhaps, make it think in ways you've never imagined? The possibilities are boundless.
            <p />
            As you encounter options to continue the story, each fragment unfolds <em>new possibilities</em>, introducing twists, turns, and potential surprises. With OPT, powered by Meta's OPT Large Language model, you can generate on-topic text or embrace unexpected deviations, introducing new characters or altering the course of events (imagine Chewbacca as the President of the United States or Eisenhower serving under Bill Clinton!).
          </p>

          <Message
            severity="warn"
            // summary="Warning"
            // sticky={true}
            style={{
              margin: "5px",
              alignItems: "center",
              flex: 1,
            }}
            text="The results generated by the language model are uncontrolled and sometimes will produce results inappropriate for some audiences. Occassionally it produces results inappropiate for any audience. Please use caution and do not share results with anyone under the age of 18. If you are offended by the results, please do not use this application. There are terms associated with using the output of this model you should become familiar with. Please see the link on our home page for more information or refer to the OpenRAIL license, generally, to understand the terms of use. The point is to have fun and be creative. If you are not having fun, please consider going outside."
          ></Message>
          {/* </Card> */}
          <h3>Story Library</h3>
          Read stories that others have started and continue them yourself, or
          use the story key from a story to search for it below. <p />
          <br />
          {page > 1 && (
            <Button label="Previous" onClick={() => setPage(page - 1)} />
          )}
          <Button label="Refresh" onClick={() => handleLoadStories(page)} />
          {/* Add a condition to check if there are more pages */}
          {/* <Button label="Next" onClick={() => setPage(page + 1)} /> */}
          <DataTable
            value={records}
            responsiveLayout="scroll"
            dataKey="_id"
            resizableColumns
            selectionMode="single"
            onSelectionChange={(e) => handleLoadStory(e.value._id)}
            paginator
            rows={5}
            showGridlines
            rowsPerPageOptions={[25]}
            // paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink RefreshButton"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            // rows={10}
            // rowsPerPageOptions={[10, 20, 50]}
            globalFilterFields={["first_text", "last_text", "_id"]}
            globalFilter={globalFilterValue1}
            header={header1}
            emptyMessage="No stories found - API may be down - try hitting Refresh button in a few seconds or minutes."
          >
            <Column
              field="step"
              header="Steps Completed"
              style={{
                width: "10%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
              sortable={true}
              sortField="step"
              body={(rowData) => (
                <Badge
                  value={rowData.step + 1}
                  size="large"
                  severity="success"
                ></Badge>
              )}
            />
            <Column
              field={(rowData) => rowData._id.slice(0, 8)}
              header="Story Key"
            />
            <Column
              field="first_text"
              header="How it started..."
              style={{
                // width: "45%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {(rowData) => (
                <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                  {rowData.first_text}
                </div>
              )}
            </Column>
            <Column
              field="last_text"
              header="...How it's going"
              style={{
                // width: "40%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {(rowData) => (
                <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                  {rowData.last_text}
                </div>
              )}
            </Column>
            {/* <Column field="timestamp" header="Last Updated" /> */}
            <Column
              field="timestamp"
              header="Last Updated"
              body={(rowData) => (
                <span>{moment(rowData.timestamp * 1000).fromNow()}</span>
              )}
              sortable
            />

            {/* <Column field="tags" header="Tags" /> */}
            {/* <Column field="timestamp" header="Last Updated" /> */}
          </DataTable>
        </Panel>
      </div >
    );
  }
  if (storyId) {
    return <FateStory storyId={storyId} />;
  }
  if (storyId && step) {
    return <FateStory storyId={storyId} />;
  }
};

export default FateApp;
