import React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
// import { Badge } from "primereact/badge";
import { Chip } from "primereact/chip";
import AnimatedCharacterModal from './AnimatedCharacterModal.tsx';

import { useState } from "react";

const Home = () => {
  // const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      {/* <div><AnimatedCharacterModal isLoading={isLoading} />    </div> */}
      <div
        className="surface-0 p-4 shadow-8 border-round"
        style={{ backgroundColor: "var(--blue-600)" }}
      >
        <div className="text-3xl font-medium text-900">
          Welcome to the (fairly maturing) NLP Playground
        </div>
        <hr />
        <div>
          <h3>NOTE: The Choose Your Own Fate app is morphing. We are now using <b>OPT 350m</b> from Meta/Facebook to generate stories. This open source model runs fast inference and generates coherent text with the existing temperature settings that is more plain-language, on task and interesting. In the backend data we are tagging the selections with a source of OPT so that we can analyze later (and upload to Hugging Face) the human selections. Feel free to start a new story or continue an existing one even if it was written by BLOOM. It would be interesting to see how the stories continue after the model changes.</h3>
          <h3>NOTE: The Choose Your Own Fate App is out of retirement!</h3><br />As of <b>August 6th, 2023</b> we have reverted to BLOOM (560 million parameters) for story generation. We have abandoned BLOOMZ, a model with better task-related abilities. The base model, and it's tokenizer, perform better at text continuation which is the name of the game for the <i>Choose Your Own Fate App</i>. BLOOMZ is trained to perform tasks, and importantly it is designed to "stop" talking. We can't have the model end our stories prematurely so we have reverted to BLOOM. We have also updated the UI to be more responsive and to show the model's progress as it generates the next story chunk. We hope you enjoy the updates!
          <h3>NOTE: The Choose Your Own Fate App is retiring!</h3><br />As of <b>July 21st, 2023</b> we have found issues with the model generating out of bound text and are going to retire the story generator. We didn't change the code or the model but somehow after some other issues with the hosting machine when we remounted the model the output is becoming less deterministic and coherent. If you look at a few of the last stories you might find characters from non-English languages or foreign phrases sprinkled into the text. Adjusting temperature, top_k, and other factors hasn't helped.<br />What we will do instead is publish the stories in HTML format for reading and sharing and use a static site generator to keep that site running fast.<br />I'm also excited to say that this retirement means we can focus on publishing the front end and backend code for the Choose Your Own Fate app so you can run it locally and generate your own stories (or be used as the basis for another story generator). Stay tuned for that after we spend some time tidying up the code!
        </div>
        <p />
        <div className="text-500 mb-3">
          Select an App from the menu to get started or read on for a summary of
          the applications offered in this Natural Language Text Generation
          Playground. <br /> Get started with <b>BLOOM</b>, the largest language
          model on the planet (as of January 2023...) and see what it can do!
        </div>
        <Divider align="Center" type="solid">
          {/* <Button label="Choose Your Own Fate" icon="pi pi-book"></Button> */}
          {/* <Badge size="xlarge" value="pi pi-book"> */}
          {/* <i icon="pi pi-book"></i> */}
          {/* </Badge> */}
          <Chip icon="pi pi-book" label="Choose Your Own Fate!"></Chip>
        </Divider>
        <h2>
          Play the <b>Choose Your Own Fate</b> app that uses <b>BLOOM</b> to
          generate a story with some interesting <em>flavor</em>.
        </h2>{" "}
        <br />
        This app uses a tolerable set of ranges to give you an interesting set of
        options to choose from, guaranteed to be unique and different each time
        you press the magic "<em>Generate</em>" button. Since each option has its
        own parameters and "<em>seeds</em>" per run you're likely to find a great
        continuation to the text - be entirely on topic or taking a dramatic turn!
        <p />
        The app will start your story writing with a random prompt from our{" "}
        <a href="https://gist.github.com/JHenzi/9715e908201ee7132ee27af15fffc77c">
          GitHub Gist
        </a>
        , a collection of prompts that might lead you down an interesting path -{" "}
        <em>
          or you may have a random chance at pulling today's top news, a headline,
          summary or abstract!
        </em>
        <p />
        I personally find the stories generated from the headlines the most
        interesting as they have a chance at containing "Named Entities" (proper
        nouns, places, people) and it's also fun to see the model generate text in
        context of the latest events it actually has no knowledge of!
        <p /> As mentioned each story-step generation is unique and backed by a
        randomized set of parameters. If one of the three options isn't suitable,
        that's okay, simply generate more options to choose from until you find
        the right one to fit your story.
        <p />
        As you make your selections the story will grow and saved for sharing or
        just to read later. You can also save your story as a PDF file for
        printing or sharing (<em>coming soon!</em>). See what stories already have
        been written or search for stories by their ID for later lookup.
        <p />
        <h4><b>Updated April 14th, 2023: </b> <em>UI updated April 14th, backend updated. Model in use is now <b>BLOOMZ</b> which is trained on the T0 dataset for better turn based and goal directed dialog (with some reasoning skills shown to boot). Enjoy the same in the Playground. Now upon selecting the next story "chunk" or continuation the UI will start generating the next continuation automatically so no more required button clicks between steps!</em></h4>
        <h4><b>Updated March 23rd, 2023:</b> <em>UI updated. Backend updated with parameter settings.</em></h4>
        <h4><b>Updated July 21st, 2023:</b> <em>Removing buttons to start or continue stories as the application is reaching retirement. We'll open source the code and post it to GitHub</em></h4>
        <Divider align="Center" type="solid">
          {/* <Button label="Play in the Playground" icon="pi pi-eraser"></Button> */}
          <Chip
            icon="pi pi-fw pi-align-center"
            label="Play in the NLP Playground"
          ></Chip>
        </Divider>
        <h2>
          Visit the Playground and play with{" "}
          <a href="https://huggingface.co/docs/transformers/model_doc/bloom">
            <b>BLOOM</b>, the world's largest language model
          </a>
          .
        </h2>
        Built by the{" "}
        <a href="https://bigscience.huggingface.co/">BigScience team</a> (at{" "}
        <a href="https://huggingface.co/">HuggingFace</a>), it is a 1.5 billion
        parameter model that can generate text of various flavors (be warned!)
        though you get to set your own parameters. <p />
        Let it loose on a prompt and see what it comes up with! Run it for 5
        seconds on long prompts or for up to 2 minutes on short prompts. Set the
        temperature to get a varied response or consider the "top_k" values that
        define the vocabulary the model should consider when searching for the
        next "best word". Build as you go, but know your results aren't saved -
        you'll want to copy and paste the interesting results and share with
        friends.
        <Divider align="Center" type="solid">
          <Chip icon="pi pi-info-circle" label="What's Next?"></Chip>
        </Divider>
        We're working on a few more apps to make this a more interesting and there
        is a growing backlog to improve <b>Choose Your Own Fate</b>.
        <p />
        Recently added was the ability to not only save the <b>options</b> a user
        creates but the various <b>attributes</b> about the generated selections.
        Training is ongoing to define a model that may further enhance the story
        generation. We would like to refine and provide the "best" options for the
        user to select from by understanding the <em>language</em> people select
        for (tone, coherency), but also seek and experiment with the best fit
        model parameters.
        <p></p>
        As our dataset to perform this analysis is still quite small feel free to
        generate many stories, with many steps. Our models will only improve with
        more user feedback and interactions.
        <p />
        There is <b>zero</b> usage of cookies or other tracking mechanisms on this
        site, however, feedback you provide is saved in a database for later
        analysis. Even the generated unique identifiers are randomized and not
        connected to a user. While there are plans for future apps to be added to
        our NLP playground, some may requiring authentication or cookies - those
        terms will be made clear for the application sections/apps in question.
        <h3>Enjoy, and try to have fun!</h3>
        <p />
        <em>
          <h3>
            Joe{" "}
            <a href="https://henzi.org" style={{ color: "orange" }}>
              Henzi
            </a>
          </h3>
        </em>
        <hr />
        <em>
          <b>Note:</b> All stories and text generated by <b>BLOOM</b> are covered
          by the following License:{" "}
          <a href="BLOOMLICENSE.html" style={{ color: "orange" }}>
            BLOOM License (Big Science RAIL v1.0)
          </a>
          . Using any generated text/stories for harm, spam or other illegal
          purposes is strictly prohibited by the License. Please read the License
          carefully before considering distributing any generated text/stories.
          The work you create is yours, but the text generated by the model comes
          from a variety of inputs and careful consideration should be made before
          sharing or taking the output of the model as any sort of guidance. The
          intent is to provide the public with a playground for experimentation
          and exploration of the capabilities of the model. The model is not
          intended to be taken seriously (like life itself at times).
        </em>
        <hr></hr>
      </div>
    </>
  );
};

export default Home;
